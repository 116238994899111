import {DepositorUserDto, OrganisationalPersonDto} from '@fixrate/fixrate-query'
import {GenericUser} from '#blocks/UsersList/UsersList'
import {SecurityRole} from '@fixrate/fixrate-security'
import {useSelector} from '#state/useSelector'

export function personIsPartner(person: OrganisationalPersonDto): boolean {
    return person.securityRoles.includes('DEPOSITOR_PARTNER_ADVISOR') || person.securityRoles.includes('DEPOSITOR_PARTNER_SUPPORT') || person.securityRoles.includes('DEPOSITOR_PARTNER_ACCOUNTANT')
}

export function userIsPartner(user: GenericUser): boolean {
    return user.roles.includes('DEPOSITOR_PARTNER_ADVISOR') || user.roles.includes('DEPOSITOR_PARTNER_SUPPORT') || user.roles.includes('DEPOSITOR_PARTNER_ACCOUNTANT')
}

export function userIsSupport(user: DepositorUserDto): boolean {
    return user.roles.includes('DEPOSITOR_PARTNER_SUPPORT')
}

export function partnerRoles(): SecurityRole[] {
    return [
        'DEPOSITOR_PARTNER_ADVISOR',
        'DEPOSITOR_PARTNER_SUPPORT',
        'DEPOSITOR_PARTNER_ACCOUNTANT',
    ]
}

export function useAuthorization(organisationId?: string) {
    const association = useSelector(state => {
        if (organisationId) {
            return state.session.associations.find(association => association.organisation && association.organisation.id === organisationId)
        } else {
            return state.session.association
        }
    })

    const roles = association ? association.roles : []
    const permissions = association ? association.permissions : []

    return {
        depositor: {
            hasAdminRole: !!association && association.organisationType === 'DEPOSITOR' && association.roles.includes('DEPOSITOR_ADMIN'),
            hasPartnerRole: roles.some(role => partnerRoles().includes(role)),
            hasSupportRole: !!association && association.organisationType === 'DEPOSITOR' && association.roles.includes('DEPOSITOR_PARTNER_SUPPORT'),
            hasAccountHolderRole: !!association && association.organisationType === 'DEPOSITOR' && association.roles.includes('DEPOSITOR_ACCOUNT_HOLDER'),
            hasOrderRole: !!association && association.organisationType === 'DEPOSITOR' && association.roles.includes('DEPOSITOR_ORDER'),
            hasPartnerAccountantRole: !!association && association.organisationType === 'DEPOSITOR' && association.roles.includes('DEPOSITOR_PARTNER_ACCOUNTANT'),
            hasPaymentRole: !!association && association.organisationType === 'DEPOSITOR' && association.roles.includes('DEPOSITOR_PAYMENT'),
        },
        bank: {
            hasAdminRole: !!association && association.organisationType === 'BANK' && association.roles.includes('BANK_ADMIN'),
            hasAdManagerRole: !!association && association.organisationType === 'BANK' && association.roles.includes('BANK_AD_MANAGER'),
            hasCustomerServiceRole: !!association && association.organisationType === 'BANK' && association.roles.includes('BANK_CUSTOMER_SERVICE'),
            hasCoordinatorRole: !!association && association.organisationType === 'BANK' && association.roles.includes('BANK_COORDINATOR'),
        },
        partner: {
            hasAdminRole: !!association && association.organisationType === 'PARTNER' && association.roles.includes('PARTNER_ADMIN'),
        },
        roles: roles,
        permissions: permissions
    }
}

export function useIsIntegrationsAdmin() : boolean {
    const auth = useAuthorization()
    return auth?.depositor?.hasAdminRole || auth?.depositor?.hasSupportRole || auth?.depositor?.hasPartnerRole
}
