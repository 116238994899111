import { switchOrganisation } from '#app/services/thunks/session'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { useSelector } from '#app/state/useSelector'
import { OrganisationType } from '@fixrate/fixrate-query'
import { Autocomplete, Avatar, ListItemIcon, ListItemText, MenuItem, TextField } from '@mui/material'
import { KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MenuLink, OrganisationSelectorOption } from '../PageHeader'

export const selectButtonStyles = {
    fontSize: '1.4rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    borderLeft: '1px solid rgba(0,0,0,0.1)',
    height: '100%',
    borderRadius: 0,
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'none',
    },
    '& .MuiButton-startIcon': {
        marginRight: {
            xs: '0',
            md: '1rem',
        },
    },
}

export const listItemIconStyle = {
    backgroundColor: 'transparent',
    minWidth: '3.6rem',
    minHeight: '3.6rem',
}

export const listItemTextStyle = {
    '& .MuiListItemText-primary': {fontSize: '1.4rem'},
}

export const OrganizationMenuItem = ({org}: { org: OrganisationSelectorOption }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const currentDepositor = useCurrentDepositor()
    const isPartner = useSelector(state => state.session.organisationType === 'PARTNER')

    const navigateAfterSwitch = () => {
        if (isPartner) {
            navigate('/organizations')
        } else if (currentDepositor && org.organisationId) {
            navigate(window.location.pathname.replace(currentDepositor.id, org.organisationId))
        }
    }

    function onSelectRole(organisationType: OrganisationType, orgId: string) {
        dispatch(switchOrganisation(organisationType, orgId, navigateAfterSwitch))
    }

    return (
        <MenuItem
            key={org.key}
            data-cy="organization-menu-item"
            onClick={() => onSelectRole(org.organisationType, org.organisationId)}
        >
            <ListItemIcon sx={listItemIconStyle}>
                <Avatar
                    sx={{
                        backgroundColor: org.iconColor,
                        fontSize: '1.4rem',
                        fontWeight: '600',
                    }}
                >{org.iconName}
                </Avatar>
            </ListItemIcon>
            <ListItemText data-cy="organization-name" sx={listItemTextStyle}>
                {org.name}
            </ListItemText>
        </MenuItem>
    )
}

export const MenuItemLink = ({ item, link }: { item: MenuLink, link: string }) => {
    const navigate = useNavigate()
    const {t} = useTranslation()

    return (
        <MenuItem
            sx={{
                minWidth: '30rem',
            }}
            data-cy={item?.dataCy ? item.dataCy : "menu-link-item"} key={item.text}
            onClick={() => navigate(link)}
        >
            <ListItemIcon sx={listItemIconStyle}>
                <i className={item.icon}/>
            </ListItemIcon>
            <ListItemText sx={listItemTextStyle}>
                {t(item.text)}
            </ListItemText>
            {item.warning && (
                <ListItemIcon sx={listItemIconStyle}>
                    <i className="red ri-error-warning-line"/>
                </ListItemIcon>
            )}
        </MenuItem>
    )
}

type AutoCompleteOption = {
    label: string,
    id: string,
}

export const OrganisationSearch = ({organisationOptions}: { organisationOptions: OrganisationSelectorOption[] }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const currentDepositor = useCurrentDepositor()

    function onSelectRole(orgId: string) {
        if (!orgId) {
            return
        }
        const organisationType = organisationOptions.find(org => org.organisationId === orgId)?.organisationType
        dispatch(switchOrganisation(organisationType, orgId))
        if (currentDepositor && orgId) {
            navigate(window.location.pathname.replace(currentDepositor.id, orgId))
        }
    }

    return (
        <MenuItem
            disableRipple={true}
            sx={{
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                '&:focus': {
                    backgroundColor: 'transparent',
                },
                '&.Mui-focusVisible': {
                    backgroundColor: 'transparent',
                },
                '& .MuiAutocomplete-listbox': {
                    maxHeight: '23rem',
                },
            }}
        >
            <Autocomplete
                disablePortal
                id="organization-search"
                onChange={(e, option: AutoCompleteOption) => onSelectRole(option?.id)}
                sx={{
                    width: '100%',
                    fontSize: '1.4rem',
                }}
                options={organisationOptions?.map(option => {
                    return {
                        label: option.name,
                        id: option.organisationId,
                    }
                })}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    e.stopPropagation()
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Søk"
                    />
                )}
            />
        </MenuItem>
    )
}
