import {CurrencyOutput, DateOutput} from '#components'
import {FundInventoryReport} from '@fixrate/fixrate-report'
import {Box, Stack, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material'
import {COLORS} from '#app/colors/colors'
import {useTranslation} from "react-i18next";
import {useCurrencyOutputWithCurrency} from '#app/components/CurrencyOutput/useCurrencyOutput'
import {Currency} from "@fixrate/fixrate-query";

type Props = {
    report: FundInventoryReport
    currency: Currency
}

export default function InventoryTable({report, currency}: Props) {
    const {t} = useTranslation()
    const Currency = useCurrencyOutputWithCurrency(currency)
    const filteredEntries = report.fundPlacementInventoryEntries.filter(entry =>
        // Filter out entries with no data.
        (entry.unitQuantity != null && entry.unitQuantity != 0) ||
        (entry.incomingBalance != null && entry.incomingBalance != 0) ||
        (entry.outgoingBalance != null && entry.outgoingBalance != 0) ||
        (entry.cashFlow != null && entry.cashFlow != 0)
    )

    return (
        <Table size={'small'} style={{pageBreakInside: 'avoid', borderCollapse: 'collapse'}}>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={6} align={'left'} sx={{borderBottom: `1px solid ${COLORS.FOREST_GREEN}`, fontVariant: 'small-caps', letterSpacing: '0.08em', textTransform: 'uppercase', color: COLORS.FOREST_GREEN}}>{t('pages-fund-reports-inventory-table.inventory')}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{t('pages-fund-reports-inventory-table.colSecurities')}</TableCell>
                    <TableCell align={'right'}>{t('pages-fund-reports-inventory-table.colUnits')}</TableCell>
                    <TableCell align={'right'}>{t('pages-fund-reports-inventory-table.colPricePerDate', {date: DateOutput.formatDate(report.toDate)})}</TableCell>
                    <TableCell align={'right'}>{t('pages-fund-reports-inventory-table.colClosingBalance')}</TableCell>
                    <TableCell align={'right'}>{t('pages-fund-reports-inventory-table.colIncomingBalance')}</TableCell>
                    <TableCell align={'right'} sx={{pr: 0}}>{t('pages-fund-reports-inventory-table.colTransactions')}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {filteredEntries.map(entry => (
                    <TableRow key={entry.fundId}>
                        <TableCell>
                            <Stack>
                                <Box component="span" sx={{fontWeight: 600}}>{entry.fundName}</Box>
                                <Box component="span" sx={{color: '#6F7271'}}>{entry.fundIsin}</Box>
                            </Stack>
                        </TableCell>
                        <TableCell align={'right'}>{CurrencyOutput.formatNoCode(entry.unitQuantity, 4)}</TableCell>
                        <TableCell align={'right'}>{Currency(entry.outgoingBalance/entry.unitQuantity, { decimals: 4 })}</TableCell>
                        <TableCell align={'right'}>{Currency(entry.outgoingBalance)}</TableCell>
                        <TableCell align={'right'}>{Currency(entry.incomingBalance)}</TableCell>
                        <TableCell align={'right'} sx={{pr: 0}}>{Currency(entry.cashFlow)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
