import { useRef } from 'react';
import {Chart} from 'chart.js'
import 'chartjs-adapter-date-fns'
import {InterestOutput} from '#components'
import {FundInventoryReport} from '@fixrate/fixrate-report'
import {CHART_COLORS} from '#app/colors/colors'
import {Box, Stack} from '@mui/material'
import LegendSquare from "#pages/portfolio-depositor/Reports/FundReports/InventoryReport/LegendSquare";

const COLORS = {
    FOREST_GREEN: '#21362C',
    SILVER_GRAY: '#6F7271',
    PURPLE: '#4D2A98',
    SUNSET_ORANGE: '#FFA621',
    BURNT_ORANGE: '#B44A06',
}

type Props = {
    report: FundInventoryReport
    pdfMode?: boolean
}

type LineChart = Chart<'line', string[] | number[]>

export default function FundsDevelopmentChart({report, pdfMode=false}: Props) {
    const chartRef = useRef<LineChart | null>(null)

    const filteredEntries = report.fundPlacementInventoryEntries.filter(entry => {
        // Only include entries with a non-null and non-zero proportion.
        return entry.proportionOfPortfolio != null && entry.proportionOfPortfolio != 0
    })

    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        const ctx = canvas?.getContext('2d')
        if (ctx && !chartRef.current) {
            chartRef.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: Object.keys(report.niborReturnRates),
                    datasets: [
                        {
                            label: 'NIBOR3M',
                            data: Object.values(report.niborReturnRates),
                            borderColor: COLORS.BURNT_ORANGE,
                            backgroundColor: COLORS.BURNT_ORANGE,
                            borderWidth: 2,
                            pointRadius: 0,
                            borderDash: [8, 4],
                            pointBackgroundColor: COLORS.BURNT_ORANGE,
                            pointBorderColor: 'transparent',
                            spanGaps: true,
                            tension: 0.1,
                        },
                        ...filteredEntries.map((entry, i) => (
                            {
                                label: entry.fundIsin + ' – ' + entry.fundName,
                                data: Object.values(entry.fundReturnRates),
                                borderColor: CHART_COLORS[i],
                                backgroundColor: CHART_COLORS[i],
                                borderWidth: 2,
                                pointRadius: 0,
                                pointBackgroundColor: CHART_COLORS[i],
                                pointBorderColor: 'transparent',
                                spanGaps: true,
                                tension: 0.1,
                            }
                        ))
                    ],
                },
                options: {
                    color: COLORS.SILVER_GRAY,
                    aspectRatio: 5 / 2,
                    maintainAspectRatio: !pdfMode,
                    responsive: !pdfMode,
                    animation: pdfMode ? false : {duration: 500},
                    hover: {
                        mode: 'nearest',
                        intersect: true,
                    },
                    scales: {
                        x: {
                            display: true,
                            type: 'time',
                            ticks: {
                                font: {
                                    size: 10,
                                    family: "'Montserrat'",
                                    weight: '500',
                                },
                                color: COLORS.SILVER_GRAY,
                            },
                            grid: {
                                drawBorder: false,
                                display: false,
                            },
                            time: {
                                displayFormats: {
                                    day: 'dd. MMM',
                                    month: 'MMM yy',
                                },
                                tooltipFormat: 'dd. MMMM yyyy',
                            },
                        },
                        y: {
                            ticks: {
                                display: true,
                                font: {
                                    family: "'Montserrat'",
                                    weight: '500',
                                },
                                color: COLORS.SILVER_GRAY,
                                callback: (value) => InterestOutput.format(value),
                            },
                            grid: {
                                drawBorder: false,
                                borderDash: [4, 4],
                                color: context => context.tick.value === 0 ? '#00000025' : '#00000000',
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: true,
                            axis: 'x',
                            mode: 'nearest',
                            intersect: false,
                            callbacks: {
                                label: (tooltipItem) => {
                                    const dataset = chartRef.current.data.datasets[tooltipItem.datasetIndex]
                                    const value = dataset.data[tooltipItem.dataIndex]
                                    return value !== undefined && value !== null ? `${dataset.label}: ${InterestOutput.format(value)}` : ''
                                },
                            },
                        },

                    },
                },
            })
        }
    }

    const webStyle = {
        maxWidth: '100%'
    }
    const pdfStyle = {
        width: '80rem',
        height: '32rem',
    }
    const canvasStyle = pdfMode ? pdfStyle : webStyle

    return (
        <Stack direction={{xs: 'column', lg: 'row'}} spacing={2}>
            <Box sx={{maxWidth: '100%', width: '100rem'}}>
                <canvas ref={canvasCallback} style={canvasStyle} id={'funds-development-chart-canvas'}/>
            </Box>
            <Stack spacing={1} direction={pdfMode ? 'row' : 'column'}>
                <Stack direction={'row'} alignItems={'start'} spacing={1}>
                    <LegendSquare color={COLORS.BURNT_ORANGE}/>
                    <Stack>
                        <Box component="span" sx={{fontSize: 12, fontWeight: 600}}>NIBOR3M</Box>
                    </Stack>
                </Stack>
                {filteredEntries.map((entry, i) => (
                    <Stack key={entry.fundId} direction={'row'} alignItems={'start'} spacing={1}>
                        <LegendSquare color={CHART_COLORS[i]}/>
                        <Stack>
                            <Box component="span" sx={{fontSize: 12, fontWeight: 600}}>{entry.fundName}</Box>
                            <Box component="span" sx={{fontSize: 12, color: '#6F7271'}}>{entry.fundIsin}</Box>
                        </Stack>
                    </Stack>))}
            </Stack>
        </Stack>
    )
}
