import { useMemo } from 'react'
import { PlatformFeeSteps } from '#pages/FundCustomers/FundCustomerDetail/PlatformFee/SetPlatformFee'
import {
    CountryCode,
    Currency,
    DepositorPartnerProposalStatus,
    DocumentSubscriptionType,
    FundCategory,
    InvestmentCategory
} from '@fixrate/fixrate-query'
import format from 'date-fns/format'
import {useDispatch} from 'react-redux'
import config from '../../../config'
import {handleNetworkErrors, handleStatusCodeErrors} from '../errorhandling'
import {postCommand, postCommandOptions} from './command'
import {
    UpdatePersonContact,
    UpdatePersonInfo,
    UpdatePersonNationality,
    UpdatePersonOwnership,
    UpdatePersonPep
} from './useCommand.types'

const API_BASE_URL = config().apiUrl

export function useCommand() {
    const dispatch = useDispatch()
    return useMemo(() => ({
        createOrder: (adId: string, volume: number, depositorId: string, accountCreatorUserId: string, settlementAccountId: string, proposalId: string) =>
            postCommand(dispatch, 'CreateOrder', {
                ad: adId,
                volume: volume,
                depositorId: depositorId,
                accountCreator: accountCreatorUserId,
                settlementAccountId: settlementAccountId,
                proposalId: proposalId,
            }),

        setToAccountOnOrder: (orderId, toAccount) =>
            postCommand(dispatch, 'SetToAccount', {
                orderId: orderId,
                account: toAccount,
            }),

        setSettlementDateOnOrder: (orderId, settlementDate) =>
            postCommand(dispatch, 'SetSettlementDate', {
                orderId: orderId,
                settlementDate: settlementDate,
            }),

        sendToBank: orderId => postCommand(dispatch, 'SendOrderToBank', { orderId }),

        setAdActiveStatus: (adId, active) =>
            postCommand(dispatch, 'SetAdActive', {
                adId,
                active,
            }),

        generateIdentificationDocument: (countryCode: CountryCode, address: string, postcode?: string, tin?: string) =>
            postCommand(dispatch, 'GenerateIdentificationDocument', {
                countryCode,
                address,
                postcode,
                tin,
            }),

        removeIdentificationDocument: () => postCommand(dispatch, 'RemoveIdentificationDocument', {}),

        generateAuthorizationDocument: (attorneyUserId, attorneyInviteId, principalPersonIds, depositorId, notifiedUserId, language) =>
            postCommand(dispatch, 'GenerateAuthorizationDocument', {
                attorneyUserId,
                attorneyInviteId,
                principalPersonIds,
                depositorId,
                notifiedUserId,
                language,
            }),

        removeDocumentFromOrder: (orderId, documentId) =>
            postCommand(dispatch, 'RemoveDocumentFromOrder', {
                orderId,
                documentId,
            }),

        sendToDepositor: orderId => postCommand(dispatch, 'SendOrderToDepositor', { orderId }),

        completeOrder: (orderId, volume, startDate, nextFixedInterestPeriodStartDate, comment) =>
            postCommand(dispatch, 'CompleteOrder', {
                orderId,
                volume,
                startDate,
                nextFixedInterestPeriodStartDate,
                comment,
            }),

        cancelOrder: (orderId, category, reason) =>
            postCommand(dispatch, 'CancelOrder', {
                orderId,
                category,
                reason,
            }),

        createDepositor: (nationalIdentity, nationality) =>
            postCommand(dispatch, 'CreateDepositor', {
                nationality,
                nationalIdentity,
            }),

        generateCustomerDeclaration: depositorId =>
            postCommand(dispatch, 'GenerateCustomerDeclaration', {
                depositorId,
            }),

        setCustomerDeclarationValue: (depositorId, key, value) =>
            postCommand(dispatch, 'SetCustomerDeclarationValue', {
                depositorId,
                key,
                value,
            }),

        createCustomerDeclarationDraft: (depositorId) =>
            postCommand(dispatch, 'CreateCustomerDeclarationDraft', {
                depositorId,
            }),

        deleteCustomerDeclarationValues: (depositorId, keys) =>
            postCommand(dispatch, 'DeleteCustomerDeclarationValues', {
                depositorId,
                keys,
            }),

        removeCustomerDeclaration: depositorId =>
            postCommand(dispatch, 'RemoveCustomerDeclaration', {
                depositorId,
            }),

        deleteCustomerDeclarationBasis: (depositorId, documentId) =>
            postCommand(dispatch, 'DeleteCustomerDeclarationBasis', {
                depositorId,
                documentId,
            }),

        approveCustomerDeclaration: (depositorId, documentId) =>
            postCommand(dispatch, 'ApproveCustomerDeclaration', {
                depositorId,
                documentId,
            }),

        distributeCustomerDeclaration: depositorId =>
            postCommand(dispatch, 'DistributeCustomerDeclaration', {
                depositorId,
            }),

        registerCustomerDeclaration: (depositorId, bankId, documentId) =>
            postCommand(dispatch, 'RegisterCustomerDeclaration', {
                depositorId,
                bankId,
                documentId,
            }),

        startElectronicSignature: (processId, target, identity) => {
            const onSuccess = `${window.location.origin}/signicat-response/signature-success.html`
            const onAbort = `${window.location.origin}/signicat-response/signature-abort.html`
            return postCommand(dispatch, 'StartElectronicSignature', {
                processId,
                target,
                identity,
                onSuccess,
                onAbort,
            })
        },

        updateSignatureStatus: () => postCommand(dispatch, 'UpdateSignatureStatus', {}),

        createUserInvite: (organisationId, organisationType, firstName, lastName, email, birthDate, language) =>
            postCommand(dispatch, 'CreateUserInvite', {
                organisationId,
                organisationType,
                firstName,
                lastName,
                email,
                birthDate,
                language,
            }),

        createTellAFriend: (organisationId, email, firstName, lastName, language) =>
            postCommand(dispatch, 'TellAFriend', {
                organisationId,
                email,
                firstName,
                lastName,
                language,
            }),

        removeUserRequestFromDepositor: (depositorId, nationalIdentity, nationality) =>
            postCommand(dispatch, 'RemoveUserRequestFromDepositor', {
                depositorId,
                nationalIdentity,
                nationality,
            }),
        removeUserInvite: (organisationId, inviteId) =>
            postCommand(dispatch, 'RemoveUserInvite', {
                organisationId,
                inviteId,
            }),

        resendUserInvite: (organisationId, inviteId, email, firstName = undefined, lastName = undefined, birthDate = undefined, language = undefined) =>
            postCommand(dispatch, 'ResendUserInvite', {
                organisationId,
                inviteId,
                email,
                firstName,
                lastName,
                birthDate,
                language,
            }),

        removeUserFromDepositor: (depositorId, userToRemoveId) =>
            postCommand(dispatch, 'RemoveUserFromDepositor', {
                depositorId,
                userToRemoveId,
            }),

        removePerson: (depositorId, personId) =>
            postCommand(dispatch, 'RemovePerson', {
                depositorId,
                personId,
            }),

        removeBankUser: (bankId, userToRemoveId) =>
            postCommand(dispatch, 'RemoveBankUser', {
                bankId,
                userToRemoveId,
            }),

        removePartnerUser: (partnerId, userToRemoveId) =>
            postCommand(dispatch, 'RemovePartnerUser', {
                partnerId,
                userToRemoveId,
            }),

        addSettlementAccount: (depositorId: string, name: string, accountNumber: string, iban: string, bic: string, currency: Currency, countryCode: CountryCode) =>
            postCommand(dispatch, 'AddDepositorSettlementAccount', {
                depositorId,
                name,
                accountNumber,
                iban,
                bic,
                currency,
                countryCode
            }),

        removeSettlementAccount: (depositorId, settlementAccountId) =>
            postCommand(dispatch, 'RemoveDepositorSettlementAccount', {
                depositorId,
                settlementAccountId,
            }),

        createAd: newAd => postCommand(dispatch, 'CreateAd', newAd),

        terminateDeposit: (terminationType, depositId, settlementAccountId, terminationDate, interestRateRequirement, reason) =>
            postCommand(dispatch, 'TerminateDeposit', {
                terminationType,
                depositId,
                settlementAccountId,
                terminationDate,
                interestRateRequirement,
                reason,
            }),

        acceptUserInvite: (inviteId, accept) => postCommand(dispatch, 'AcceptInvite', {inviteId, accept}),

        setBankUserRoles: (userId, bankId, roles) =>
            postCommand(dispatch, 'SetBankUserRoles', {
                bankId,
                userToAddId: userId,
                roles,
            }),

        setPartnerUserRoles: (userId, partnerId, roles) =>
            postCommand(dispatch, 'SetPartnerUserRoles', {
                partnerId,
                userToAddId: userId,
                roles,
            }),

        setUserInviteRoles: (inviteId, organisationId, roles) =>
            postCommand(dispatch, 'SetUserInviteRoles', {
                organisationId,
                inviteId,
                roles,
            }),

        acceptBankTerms: (bankId, documentId) =>
            postCommand(dispatch, 'AcceptBankTerms', {
                bankId,
                documentId,
            }),

        acceptDepositorTerms: (depositorId, documentId) =>
            postCommand(dispatch, 'AcceptDepositorTerms', {
                depositorId,
                documentId,
            }),

        deleteAuthorizationDocument: (authorizedUserId, authorizedUserInviteId, depositorId) =>
            postCommand(dispatch, 'DeleteAuthorizationDocument', {
                depositorId,
                authorizedUserId,
                authorizedUserInviteId,
            }),

        sendTerminatedDepositToBank: depositId =>
            postCommand(dispatch, 'SendTerminationToBank', {
                depositId,
            }),

        confirmDepositTermination: depositId =>
            postCommand(dispatch, 'ConfirmDepositTermination', {
                depositId,
            }),

        confirmInterestRateChange: depositId =>
            postCommand(dispatch, 'ConfirmInterestRateChange', {
                depositId,
            }),

        setSettlementAccount: (depositId, settlementAccountId) =>
            postCommand(dispatch, 'SetSettlementAccount', {
                depositId,
                settlementAccountId,
            }),

        sendSettlementAccountChangeToBank: (depositId, settlementAccountId) =>
            postCommand(dispatch, 'SendChangeSettlementAccountToBank', {
                depositId,
                settlementAccountId,
            }),

        confirmSettlementAccountChanged: (depositId, settlementAccountId) =>
            postCommand(dispatch, 'ConfirmSettlementAccountChanged', {
                depositId,
                settlementAccountId,
            }),

        cancelDepositTermination: depositId =>
            postCommand(dispatch, 'CancelDepositTermination', {
                depositId,
            }),

        cancelChangeSettlementAccount: depositId =>
            postCommand(dispatch, 'CancelChangeSettlementAccount', {
                depositId,
            }),

        editDeposit: update => postCommand(dispatch, 'EditDeposit', update),

        updateProfile: update => postCommand(dispatch, 'UpdateProfile', update),

        setPinCode: pinCode => postCommand(dispatch, 'SetPinCode', {pinCode}),

        deletePinCode: () => postCommand(dispatch, 'DeletePinCode', {}),

        createInterestRateChange: (depositId, newInterestRate, changeDate, newInterestRateConvention, newInterestRateBenchmark) =>
            postCommand(dispatch, 'CreateInterestRateChange', {
                depositId,
                newInterestRate,
                changeDate,
                newInterestRateConvention,
                newInterestRateBenchmark
            }),

        sendInterestRateChangeToDepositor: interestRateChangeId =>
            postCommand(dispatch, 'SendInterestRateChangeToDepositor', {
                interestRateChangeId,
            }),

        cancelInterestRateChange: (interestRateChangeId, reason) =>
            postCommand(dispatch, 'CancelInterestRateChange', {
                interestRateChangeId,
                reason,
            }),

        acceptInterestRateChange: interestRateChangeId =>
            postCommand(dispatch, 'AcceptInterestRateChange', {
                interestRateChangeId,
            }),

        setNotificationSetting: (notificationType, associationType, value) =>
            postCommand(dispatch, 'SetNotificationSetting', {
                notificationType,
                associationType,
                value,
            }),

        setUserFilterNotify: (filterId, value) =>
            postCommand(dispatch, 'SetUserFilterNotification', {
                filterId,
                value,
            }),

        deleteSavedUserFilter: filterId =>
            postCommand(dispatch, 'RemoveUserFilter', {
                filterId,
            }),

        setTag: ({orderId, depositId, text, color}) =>
            postCommand(dispatch, 'SetTag', {
                orderId,
                depositId,
                text,
                color,
            }),

        uploadAccountAgreement: (orderId, fileList) => {
            const formData = new FormData()
            formData.append('content', fileList[0], fileList[0].name)
            formData.append('type', 'ACCOUNT_AGREEMENT')
            formData.append('orderId', orderId)

            const options: RequestInit = {
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
                method: 'POST',
                body: formData,
            }

            return postCommandOptions(dispatch, 'UploadAccountAgreement', `${API_BASE_URL}/api/upload`, options)
        },

        createExtensionOffer: (depositId, interestRate, terminationDate) =>
            postCommand(dispatch, 'CreateExtensionOffer', {
                depositId,
                interestRate,
                terminationDate,
            }),

        deleteExtensionOffer: depositId =>
            postCommand(dispatch, 'DeleteExtensionOffer', {
                depositId,
            }),

        registerExtension: depositId =>
            postCommand(dispatch, 'RegisterExtension', {
                depositId,
            }),

        sendExtensionOfferToDepositor: depositId =>
            postCommand(dispatch, 'SendExtensionOfferToDepositor', {
                depositId,
            }),

        acceptExtensionOffer: (depositId, documentId) =>
            postCommand(dispatch, 'AcceptExtensionOffer', {
                depositId,
                documentId,
            }),

        rejectExtensionOffer: (depositId, documentId) =>
            postCommand(dispatch, 'RejectExtensionOffer', {
                depositId,
                documentId,
            }),

        setPotential: (potentialVolume, historicInterestRate) =>
            postCommand(dispatch, 'SetPotential', {
                potentialVolume,
                historicInterestRate,
            }),

        setBankEmail: (bankId, email) =>
            postCommand(dispatch, 'SetBankEmail', {
                bankId,
                email,
            }),

        setBankEmailDomain: (bankId, emailDomain) =>
            postCommand(dispatch, 'SetBankEmailDomain', {
                bankId,
                emailDomain,
            }),

        setBankInfo: (bankId, quarter, {
            totalAssets,
            totalAssetsIncludingMortgageCredit,
            depositCoverage,
            coreCapitalCoverage
        }) =>
            postCommand(dispatch, 'SetBankInfo', {
                bankId,
                quarter,
                totalAssets,
                totalAssetsIncludingMortgageCredit,
                depositCoverage,
                coreCapitalCoverage,
            }),

        uploadBankReport: (bankId, quarter, documentType, files) => {
            /** TODO */
            const formData = new FormData()
            formData.append('bankId', bankId)
            formData.append('quarter', quarter)
            formData.append('type', documentType)
            formData.append('content', files[0], files[0].name)

            const options: RequestInit = {
                credentials: 'include',
                method: 'POST',
                body: formData,
            }

            return fetch(`${API_BASE_URL}/api/upload`, options)
                .then(response => handleStatusCodeErrors(dispatch, response))
                .catch(ex => {
                    handleNetworkErrors(dispatch)
                    return false
                })
        },

        removeBankReport: (bankId, quarter, documentType, documentId) =>
            postCommand(dispatch, 'RemoveBankReport', {
                bankId,
                quarter,
                documentType,
                documentId,
            }),

        updateDepositorEscaliConfiguration: (depositorId: string, enabled: boolean, container: string, clientId: string, updateSecret: boolean, secret: string | null) => {
            return postCommand(dispatch, 'UpdateDepositorEscaliConfiguration', {
                depositorId,
                enabled,
                container,
                clientId,
                updateSecret,
                secret,
            })
        },
        updateDepositorPandaConnectConfiguration: (depositorId: string, enabled: boolean) => {
            return postCommand(dispatch, 'UpdateDepositorPandaConnectConfiguration', {
                depositorId,
                enabled,
            })
        },
        updateDepositorSwimbirdConfiguration: (depositorId: string, enabled: boolean) => {
            return postCommand(dispatch, 'UpdateDepositorSwimbirdConfiguration', {
                depositorId,
                enabled,
            })
        },
        updateDepositorEmailDistributionConfiguration: (depositorId: string, enabled: boolean) => {
            return postCommand(dispatch, 'UpdateDepositorEmailDistributionConfiguration', {
                depositorId,
                enabled,
            })
        },

        editDepositorEmailDistributionTarget: (depositorId: string, targetId: string, email: string, subscriptions: DocumentSubscriptionType[]) => {
            return postCommand(dispatch, 'UpdateDepositorEmailDistributionTarget', {
                depositorId,
                targetId,
                email,
                subscriptions
            })
        },
        removeDepositorEmailDistributionTarget: (depositorId: string, targetId: string) => {
            return postCommand(dispatch, 'RemoveDepositorEmailDistributionTarget', {
                depositorId,
                targetId,
            })
        },

        setPinCodeAuthorization: (organisationId, organisationType, allow) =>
            postCommand(dispatch, 'SetPinCodeAuthorization', {
                organisationId,
                organisationType,
                allow,
            }),

        createDepositorOffer: depositorOfferId =>
            postCommand(dispatch, 'CreateDepositorOffer', {
                depositorOfferId,
            }),

        updateDepositorOffer: ({
                                   depositorOfferId,
                                   depositorId,
                                   accountHolderUserId,
                                   productId,
                                   interestRate,
                                   terminationDate,
                                   settlementAccountId,
                                   totalVolume,
                                   minVolume,
                                   maxVolume,
                                   expectedDuration,
                                   comment,
                                   wantExtensionOffer,
                                   deadline,
                                   banks,
                                   currency,
                               }) =>
            postCommand(dispatch, 'UpdateDepositorOffer', {
                depositorOfferId,
                depositorId,
                accountHolderUserId,
                productId,
                interestRate,
                terminationDate,
                settlementAccountId,
                totalVolume,
                minVolume,
                maxVolume,
                expectedDuration,
                comment,
                wantExtensionOffer,
                deadline,
                banks,
                currency,
            }),

        publishDepositorOffer: depositorOfferId =>
            postCommand(dispatch, 'PublishDepositorOffer', {
                depositorOfferId,
            }),

        acceptDepositorOffer: (depositorOfferId, bankId, volume) =>
            postCommand(dispatch, 'AcceptDepositorOffer', {
                depositorOfferId,
                bankId,
                volume,
            }),

        sendPartnerInvite: (partnerId: string, nationalIdentity: string, customerHasAcceptedRegistration: boolean, firstName = null, lastName = null, email = null, birthDate = null) =>
            postCommand(dispatch, 'SendPartnerInvite', {
                partnerId,
                nationalIdentity,
                customerHasAcceptedRegistration,
                nationality: 'NO',
                firstName,
                lastName,
                email,
                birthDate,
            }),

        acceptPartnerInvite: (partnerId, depositorId) =>
            postCommand(dispatch, 'AcceptPartnerInvite', {
                partnerId,
                depositorId,
            }),

        rejectPartnerInvite: (partnerId, depositorId) =>
            postCommand(dispatch, 'RejectPartnerInvite', {
                partnerId,
                depositorId,
            }),

        addPartnerAdvisor: (partnerId, depositorId, userToAdd) =>
            postCommand(dispatch, 'AddPartnerAdvisor', {
                partnerId,
                depositorId,
                userToAdd,
            }),

        setPartnerPrimaryAdvisor: (partnerId, depositorId, primaryAdvisorUserId) =>
            postCommand(dispatch, 'SetPartnerPrimaryAdvisor', {
                partnerId,
                depositorId,
                primaryAdvisorUserId,
            }),

        removePartnerAdvisor: (partnerId, depositorId, userToRemove) =>
            postCommand(dispatch, 'RemovePartnerAdvisor', {
                partnerId,
                depositorId,
                userToRemove,
            }),

        createPartnerProposal: (partnerId, depositorId, receiverId, adId, proposalId, inviteId, signers) =>
            postCommand(dispatch, 'SendPartnerProposalToDepositor', {
                partnerId,
                depositorId,
                receiverId,
                adId,
                proposalId,
                inviteId,
                signers,
            }),

        updatePartnerProposal: (proposalId: string, partnerId: string, depositorId: string, status: DepositorPartnerProposalStatus, amount: number) =>
            postCommand(dispatch, 'UpdatePartnerProposalToDepositor', {
                proposalId,
                partnerId,
                depositorId,
                status,
                amount,
            }),

        removeDepositorPartnerRelation: (partnerId: string, depositorId: string) =>
            postCommand(dispatch, 'RemoveDepositorPartnerRelation', {
                partnerId,
                depositorId,
            }),

        createInvoiceReport: (year, month) =>
            postCommand(dispatch, 'CreateInvoiceReport', {
                year,
                month,
            }),

        deleteInvoiceReport: invoiceReportId =>
            postCommand(dispatch, 'DeleteInvoiceReport', {
                invoiceReportId,
            }),

        registerBankNudge: (minimumTotalAssets, depositorIds, bankIds) =>
            postCommand(dispatch, 'RegisterBankNudge', {
                minimumTotalAssets,
                depositorIds,
                bankIds,
            }),

        uploadBankDocument: (bankId, content, fileName) => {
            const formData = new FormData()
            formData.append('bankId', bankId)
            formData.append('type', content.type === 'application/json' ? 'BANK_DOCUMENT_SOURCE' : 'BANK_DOCUMENT')
            formData.append('content', content, fileName)

            const options: RequestInit = {
                credentials: 'include',
                method: 'POST',
                body: formData,
            }

            return fetch(`${API_BASE_URL}/api/upload`, options)
                .then(response => handleStatusCodeErrors(dispatch, response))
                .catch(ex => {
                    handleNetworkErrors(dispatch)
                    return false
                })
        },

        uploadFundContractAddendum: (depositorId, content, fileName) => {
            const formData = new FormData()
            formData.append('organisationId', depositorId)
            formData.append('organisationType', 'DEPOSITOR')
            formData.append('type', 'FUND_CONTRACT_ADDENDUM')
            formData.append('content', content, fileName)

            const options: RequestInit = {
                credentials: 'include',
                method: 'POST',
                body: formData,
            }

            return fetch(`${API_BASE_URL}/api/upload`, options)
                .then(response => handleStatusCodeErrors(dispatch, response))
                .catch(ex => {
                    handleNetworkErrors(dispatch)
                    return false
                })
        },

        uploadCustomFundContract: (depositorId, content, fileName) => {
            const formData = new FormData()
            formData.append('organisationId', depositorId)
            formData.append('organisationType', 'DEPOSITOR')
            formData.append('type', 'FUND_CONTRACT')
            formData.append('content', content, fileName)

            const options: RequestInit = {
                credentials: 'include',
                method: 'POST',
                body: formData,
            }

            return fetch(`${API_BASE_URL}/api/upload`, options)
                .then(response => handleStatusCodeErrors(dispatch, response))
                .catch(ex => {
                    handleNetworkErrors(dispatch)
                    return false
                })
        },

        uploadSettlementAccountCertificate: ({depositorId, settlementAccountId, content, fileName}: {
            depositorId: string,
            settlementAccountId: string,
            content: Blob,
            fileName: string
        }) => {
            const formData = new FormData()
            formData.append('depositorId', depositorId)
            formData.append('settlementAccountId', settlementAccountId)
            formData.append('type', 'BANK_ACCOUNT_CERTIFICATE')
            formData.append('content', content, fileName)

            const options: RequestInit = {
                credentials: 'include',
                method: 'POST',
                body: formData,
            }

            return fetch(`${API_BASE_URL}/api/upload`, options)
                .then(response => handleStatusCodeErrors(dispatch, response))
                .catch(ex => {
                    handleNetworkErrors(dispatch)
                    return false
                })
        },

        uploadCustomerDeclarationBasis: (depositorId, content, fileName) => {
            const formData = new FormData()
            formData.append('depositorId', depositorId)
            formData.append('type', 'CUSTOMER_DECLARATION_BASIS')
            formData.append('content', content, fileName)

            const options: RequestInit = {
                credentials: 'include',
                method: 'POST',
                body: formData,
            }

            return fetch(`${API_BASE_URL}/api/upload`, options)
                .then(response => handleStatusCodeErrors(dispatch, response))
                .catch(ex => {
                    handleNetworkErrors(dispatch)
                    return false
                })
        },

        reportAccountStatementManually: (documentId, depositId, balance, accruedInterest, interestDate, period) =>
            postCommand(dispatch, 'AccountStatementManualReport', {
                documentId,
                deposit: depositId,
                balance,
                accruedInterest,
                interestDate,
                period,
            }),

        processBankDocumentManually: (documentId, bankId, depositId, period, type) =>
            postCommand(dispatch, 'ProcessUploadedBankDocumentManually', {
                documentId,
                bankId,
                depositId,
                period,
                type,
            }),

        deleteBankDocument: (documentId, bankId) =>
            postCommand(dispatch, 'DeleteBankDocument', {
                documentId,
                bankId,
            }),

        setProspectiveCompany: (companyNationalIdentity, companyName) =>
            postCommand(dispatch, 'SetUserProspectiveCompany', {
                companyNationalIdentity,
                companyName,
            }),

        setFixedInterestPeriodTerms: (depositId, interestRate, startDate, terminationDate) =>
            postCommand(dispatch, 'SetFixedInterestPeriodTerms', {
                depositId,
                interestRate,
                startDate,
                terminationDate,
            }),

        sendFixedInterestPeriodTermsToDepositor: (depositId, fixedInterestPeriodId) =>
            postCommand(dispatch, 'SendFixedInterestPeriodTermsToDepositor', {
                depositId,
                fixedInterestPeriodId,
            }),

        setUserLanguage: language =>
            postCommand(dispatch, 'SetUserLanguage', {
                language,
            }),

        completeCheckboxTask: messageId =>
            postCommand(dispatch, 'CompleteCheckboxTask', {
                messageId,
            }),

        setUserLegacyNotifications: legacyNotifications =>
            postCommand(dispatch, 'SetUserLegacyNotifications', {
                legacyNotifications,
            }),

        addDepositDeviationBankDetails: (depositDeviationId, interestRateCorrect, interestDateCorrect, interestRateConventionCorrect, additionalComment) =>
            postCommand(dispatch, 'AddDepositDeviationBankDetails', {
                id: depositDeviationId,
                interestRateCorrect,
                interestDateCorrect,
                interestRateConventionCorrect,
                additionalComment,
            }),

        addPerson: (depositorId, firstName, lastName, securityRoles, organisationalRoles, allowSimilarNames) =>
            postCommand(dispatch, 'AddPerson', {
                depositorId,
                firstName,
                lastName,
                securityRoles,
                organisationalRoles,
                ignoreNameLikeness: allowSimilarNames,
            }),

        updatePerson: (
            depositorId,
            personId,
            firstName,
            lastName,
            birthDate,
            email,
            address,
            taxResidencies,
            americanCitizenship,
            pep,
            pepPosition,
            pepInvestingPrivateFunds,
            controlShare,
            keyPersonRole
        ) => {
            return postCommand(dispatch, 'UpdatePerson', {
                depositorId,
                personId,
                firstName,
                lastName,
                birthDate: birthDate ? format(birthDate, 'yyyy-MM-dd') : null,
                email,
                address,
                taxResidencies,
                americanCitizenship,
                pep,
                pepPosition,
                pepInvestingPrivateFunds,
                controlShare,
                keyPersonRole,
            })
        },

        updatePersonOwnership: (
            {
                depositorId,
                personId,
                controlByShares,
                controlWithRelative,
                controlByOther,
                controlShare,
            }: UpdatePersonOwnership
        ) => {
            return postCommand(dispatch, 'UpdatePersonOwnership', {
                depositorId,
                personId,
                controlByShares,
                controlWithRelative,
                controlByOther,
                controlShare,
            })
        },

        updatePersonPep: (
            {
                depositorId,
                personId,
                pep,
                pepSelf,
                familyMemberIsPep,
                colleagueIsPep,
                pepPosition,
                pepInvestingPrivateFunds
            }: UpdatePersonPep
        ) => {
            return postCommand(dispatch, 'UpdatePersonPep', {
                depositorId,
                personId,
                pep,
                pepSelf,
                familyMemberIsPep,
                colleagueIsPep,
                pepPosition,
                pepInvestingPrivateFunds
            })
        },

        updatePersonContactInfo: (
            {
                depositorId,
                personId,
                email,
                address
            }: UpdatePersonContact
        ) => {
            return postCommand(dispatch, 'UpdatePersonContactInfo', {
                depositorId,
                personId,
                email,
                address
            })
        },

        updatePersonInfo: (
            {
                depositorId,
                personId,
                firstName,
                lastName,
                birthDate,
                email,
                address,
                keyPersonRole
            }: UpdatePersonInfo
        ) => {
            return postCommand(dispatch, 'UpdatePersonInfo', {
                depositorId,
                personId,
                firstName,
                lastName,
                birthDate,
                email,
                address,
                keyPersonRole
            })
        },

        updatePersonNationality: (
            {
                depositorId,
                personId,
                taxResidencies,
                citizenships
            }: UpdatePersonNationality
        ) => {
            return postCommand(dispatch, 'UpdatePersonNationality', {
                depositorId,
                personId,
                taxResidencies,
                citizenships
            })
        },

        invitePerson: (depositorId, personId, language) =>
            postCommand(dispatch, 'InvitePerson', {
                depositorId,
                personId,
                language,
            }),

        setPersonOrganisationalRoles: (depositorId, personId, organisationalRoles) =>
            postCommand(dispatch, 'SetPersonOrganisationalRoles', {
                depositorId,
                personId,
                organisationalRoles,
            }),

        setPersonSecurityRoles: (depositorId, personId, securityRoles) =>
            postCommand(dispatch, 'SetPersonSecurityRoles', {
                depositorId,
                personId,
                securityRoles,
            }),

        startFundBuyCheckout: (depositorId, shoppingCartId, fundAmounts) =>
            postCommand(dispatch, 'StartFundBuyCheckout', {
                depositorId,
                shoppingCartId,
                fundAmounts,
            }),

        startFundSellCheckout: (depositorId, shoppingCartId, fundPlacementAmounts, settlementAccountId) =>
            postCommand(dispatch, 'StartFundSellCheckout', {
                depositorId,
                shoppingCartId,
                fundPlacementAmounts,
                settlementAccountId,
            }),

        acknowledgeImportedPeopleChanges: (depositorId, importedPeopleInfoType) =>
            postCommand(dispatch, 'AcknowledgeImportedPeopleChanges', {
                depositorId,
                importedPeopleInfoType,
            }),

        setDepositorSupportCategory: (depositorId: string, depositorSupportCategory: 'MANAGED' | 'SELF_SERVICED') =>
            postCommand(dispatch, 'SetDepositorSupportCategory', {
                depositorId,
                depositorSupportCategory,
            }),

        setDepositorNoBeneficialOwners: (depositorId: string, noBeneficialOwners: boolean) =>
            postCommand(dispatch, 'SetDepositorNoBeneficialOwners', {
                depositorId,
                noBeneficialOwners,
            }),

        depositorHasSingleOwner: (depositorId: string, depositorHasSingleOwner: boolean, ownerPersonId: string) =>
            postCommand(dispatch, 'DepositorHasSingleOwner', {
                depositorId,
                depositorHasSingleOwner,
                ownerPersonId,
            }),

        registerFundCustomer: (nationalIdentity: string) =>
            postCommand(dispatch, 'RegisterFundCustomer', {
                nationalIdentity,
            }),

        setDepositorFundMinOrderVolume: (depositorId: string, portfolioId: string, minVolume: number) =>
            postCommand(dispatch, 'SetDepositorFundMinOrderVolume', {
                depositorId,
                portfolioId,
                minVolume,
            }),

        setDepositorFundCid: (depositorId: string, portfolioId: string, cid: string) =>
            postCommand(dispatch, 'SetDepositorFundCid', {
                depositorId,
                portfolioId,
                cid,
            }),

        setDepositorFundEnabled: (depositorId: string, fundEnabled: boolean, fundBuyEnabled: boolean) =>
            postCommand(dispatch, 'SetDepositorFundEnabled', {
                depositorId,
                fundEnabled,
                fundBuyEnabled,
            }),

        setDepositorFundPlatformFee: (depositorId: string, fundCategory: FundCategory, startDate: string, platformFeeSteps: PlatformFeeSteps | null) =>
            postCommand(dispatch, 'SetDepositorFundPlatformFee', {
                depositorId,
                fundCategory,
                startDate,
                platformFeeSteps,
            }),

        removeDepositorFundPlatformFeeEntry: (depositorId: string, fundCategory: FundCategory, startDate: string) =>
            postCommand(dispatch, 'RemoveDepositorFundPlatformFeeEntry', {
                depositorId,
                fundCategory,
                startDate,
            }),

        changeDepositorFundPlatformFixedMonthlyFee: (depositorId: string, startYearMonth: string, fixedMonthlyFee: number | null) =>
            postCommand(dispatch, 'ChangeDepositorFundPlatformFixedMonthlyFee', {
                depositorId,
                startYearMonth,
                fixedMonthlyFee,
            }),

        cancelFundBuyOrder: fundBuyOrderId =>
            postCommand(dispatch, 'CancelFundBuyOrder', {
                fundBuyOrderId,
            }),

        registerFundBuyOrderInFa: fundBuyOrderId =>
            postCommand(dispatch, 'RegisterFundBuyOrderInFa', {
                fundBuyOrderId,
            }),

        confirmFundBuyOrderPayment: (fundBuyOrderIds: string[]) =>
            postCommand(dispatch, 'ConfirmFundBuyOrderPayment', {
                fundBuyOrderIds,
            }),

        registerFundBuyOrderPayment: fundBuyOrderId =>
            postCommand(dispatch, 'RegisterFundBuyOrderPayment', {
                fundBuyOrderId,
            }),

        registerFundBuyOrderExecutingInMarket: fundBuyOrderId =>
            postCommand(dispatch, 'RegisterFundBuyOrderExecutingInMarket', {
                fundBuyOrderId,
            }),

        completeFundBuyOrder: (fundBuyOrderId, unitQuantity, unitPrice, amount, roundingError, transactionDate, settlementDate) =>
            postCommand(dispatch, 'CompleteFundBuyOrder', {
                fundBuyOrderId,
                unitQuantity,
                unitPrice,
                transactionDate,
                settlementDate,
                amount,
                roundingError,
            }),

        registerFundSellOrderExecution: (fundSellOrderId, unitQuantity, unitPrice, transactionDate, settlementDate, amount, roundingError) =>
            postCommand(dispatch, 'RegisterFundSellOrderExecution', {
                fundSellOrderId,
                unitQuantity,
                unitPrice,
                transactionDate,
                settlementDate,
                amount,
                roundingError,
            }),

        registerFundSellOrderExecutingInMarket: fundSellOrderId =>
            postCommand(dispatch, 'RegisterFundSellOrderExecutingInMarket', {
                fundSellOrderId,
            }),

        registerFundSellOrderInFa: fundSellOrderId =>
            postCommand(dispatch, 'RegisterFundSellOrderInFa', {
                fundSellOrderId,
            }),

        cancelFundSellOrder: fundSellOrderId =>
            postCommand(dispatch, 'CancelFundSellOrder', {
                fundSellOrderId,
            }),

        completeFundSellOrder: fundSellOrderId =>
            postCommand(dispatch, 'CompleteFundSellOrder', {
                fundSellOrderId,
            }),

        editFundPlacementTransaction: (
            fundPlacementId: string,
            transactionId: string,
            transactionDate: Date,
            settlementDate: Date,
            unitPrice: number,
            unitQuantity: number,
            amount: number,
            roundingError: number,
            comment: string | null,
            commentForDepositor: string | null,
            type: string
        ) =>
            postCommand(dispatch, 'EditFundPlacementTransaction', {
                fundPlacementId,
                transactionId,
                transactionDate: format(transactionDate, 'yyyy-MM-dd'),
                settlementDate: format(settlementDate, 'yyyy-MM-dd'),
                unitPrice,
                unitQuantity,
                amount,
                roundingError,
                comment,
                commentForDepositor,
                type,
            }),

        // @ts-ignore because blah blah.
        importFundTransactions: (depositorId: string, transactions: any[]) =>
            postCommand(dispatch, 'ImportFundTransactions', {
                depositorId,
                transactions,
            }),

        changeFundPlacementShareClass: (fundPlacementId: string, fromFundShareClassId: string, toFundShareClassId: string, toFundId: string, transactionDate) =>
            postCommand(dispatch, 'ChangeFundPlacementShareClass', {
                fundPlacementId,
                fromFundShareClassId,
                toFundShareClassId,
                toFundId,
                transactionDate: format(transactionDate, 'yyyy-MM-dd'),
                settlementDate: format(transactionDate, 'yyyy-MM-dd'),
            }),

        moveFundPlacementShareClass: (fundPlacementId: string, fromShareClassId: string, toShareClassId: string, toFundId: string, moveDate) =>
            postCommand(dispatch, 'MoveFundPlacementShareClass', {
                fundPlacementId,
                fromShareClassId,
                toShareClassId,
                toFundId,
                moveDate: format(moveDate, 'yyyy-MM-dd'),
            }),

        setUnifiedInbox: (unifiedInbox: boolean) =>
            postCommand(dispatch, 'SetUnifiedInbox', {
                unifiedInbox,
            }),

        enableCurrencyForBank: (bankId: string, currency: string, enabled: boolean) =>
            postCommand(dispatch, 'EnableCurrencyForBank', {
                bankId,
                currency,
                enabled,
            }),

        changeMandatePolicy: (depositorId: string, investmentCategory: InvestmentCategory, enabled: boolean) =>
            postCommand(dispatch, 'ChangeMandatePolicy', {
                depositorId,
                investmentCategory,
                enabled,
            }),

        storeUiSetting: (key: string, value: unknown) =>
            postCommand(dispatch, 'StoreUiSetting', {
                key,
                value
            }),

    }), [dispatch])
}
