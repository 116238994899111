import { FOREST_GREEN, SPRING_GREEN } from "#app/colors/colors"

const FixrateLogo = ({ className, maxWidth, darkMode }: { className?: string, maxWidth?: string, darkMode?: boolean}) => {
    const logoTextColor = darkMode ? "white" : FOREST_GREEN[500]
    return (
        <svg className={className} style={{maxWidth: maxWidth}} width="358" viewBox="0 0 358 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.537 63.0098H2.02607V78.5265H27.537V63.0098Z" fill={SPRING_GREEN[500]}/>
            <path d="M17.5597 57.3661H2V41.5963H34.1511L54.1341 30.5491H75.7323V46.3189H45.6704C45.6704 46.3189 41.3669 46.2824 36.9957 48.7976L26.3334 55.1066C26.3334 55.1066 22.7385 57.3661 17.5597 57.3661Z" fill={SPRING_GREEN[500]}/>
            <path d="M17.5597 35.817H2V20.0472H34.1511L54.1341 9H75.7323V24.7698H45.6704C45.6704 24.7698 41.5154 24.6211 36.9957 27.2485L26.3334 33.5575C26.3334 33.5575 22.7385 35.817 17.5597 35.817Z" fill={SPRING_GREEN[500]}/>
            <path d="M143.892 30.5438H132.527V40.5865H143.892V30.5438Z" fill={logoTextColor}/>
            <path d="M143.892 49.2438H132.527V79.9798H143.892V49.2438Z" fill={logoTextColor}/>
            <path d="M292.318 29.0436L280.846 33.5627L280.791 40.6283H272.968L273.02 49.262H280.846V79.9224H292.318V49.262H298.846L301.92 40.61H292.318V29.0436Z" fill={logoTextColor}/>
            <path d="M254.261 40.6179V43.4384H254.139C252.917 42.0373 251.37 41.0588 249.497 40.5057C247.624 39.9525 245.73 39.676 243.818 39.676C240.601 39.676 237.722 40.2474 235.177 41.3902C232.632 42.533 230.485 44.0724 228.735 46.0084C226.984 47.9444 225.651 50.1753 224.736 52.7009C223.819 55.2266 223.363 57.854 223.363 60.5832C223.363 63.3124 223.811 65.9138 224.708 68.382C225.604 70.8529 226.914 73.0211 228.646 74.8815C230.376 76.7444 232.502 78.229 235.026 79.3353C237.55 80.4416 240.418 80.9948 243.636 80.9948C245.589 80.9948 247.504 80.6712 249.374 80.0267C251.247 79.3823 252.834 78.4143 254.136 77.1227H254.259V79.9693H265.695L265.627 40.6179H254.261ZM253.107 67.9672C252.701 68.776 252.151 69.504 251.443 70.1458C249.807 71.6252 247.606 72.3636 244.834 72.3636C242.909 72.3636 241.265 72.0192 239.9 71.3278C238.532 70.639 237.417 69.7336 236.553 68.6169C235.688 67.5001 235.06 66.2347 234.677 64.8231C234.291 63.4116 234.099 61.9817 234.099 60.5363C234.099 59.0569 234.291 57.6036 234.677 56.1763C235.06 54.7465 235.688 53.4654 236.553 52.333C237.417 51.2007 238.543 50.2796 239.929 49.5725C241.314 48.8655 243.008 48.5132 245.008 48.5132C246.277 48.5132 247.462 48.6933 248.559 49.0559C249.656 49.4186 250.599 49.9274 251.388 50.5823C252.177 51.2398 252.81 52.0121 253.235 52.9227C254.011 54.59 254.407 57.173 254.407 60.3641C254.405 63.2576 254.149 65.8851 253.107 67.9672Z" fill={logoTextColor}/>
            <path d="M209.041 40.6492H197.985V79.8781H209.372L209.679 49.2751H216.09L222.306 40.6466H209.041V40.6492Z" fill={logoTextColor}/>
            <path d="M346.093 54.1907C345.315 51.4223 344.051 48.9541 342.301 46.7911C340.55 44.6281 338.312 42.9008 335.59 41.6171C332.868 40.3334 329.671 39.6915 326.004 39.6915C322.724 39.6915 319.739 40.2212 317.042 41.2779C314.346 42.3346 312.028 43.7827 310.082 45.6196C308.136 47.459 306.635 49.6351 305.58 52.1529C304.523 54.6708 303.996 57.3895 303.996 60.3092C303.996 63.3306 304.51 66.0989 305.539 68.6168C306.565 71.1346 308.024 73.2976 309.915 75.111C311.804 76.9244 314.109 78.3203 316.834 79.3013C319.556 80.2824 322.612 80.7729 326.004 80.7729C330.893 80.7729 335.061 79.7657 338.508 77.7515C339.714 77.047 340.811 76.1729 341.8 75.1397L334.241 69.9031C333.782 70.3258 333.233 70.738 332.589 71.1424C330.867 72.2252 328.809 72.7653 326.42 72.7653C323.086 72.7653 320.53 71.9852 318.751 70.4249C316.972 68.8646 316 66.3468 315.834 62.874H346.927C347.148 59.8552 346.87 56.959 346.093 54.1907ZM315.834 56.0797C315.888 55.3257 316.068 54.4699 316.375 53.5123C316.68 52.5573 317.209 51.6493 317.959 50.7935C318.71 49.9377 319.71 49.2202 320.96 48.641C322.211 48.0617 323.779 47.7721 325.67 47.7721C328.559 47.7721 330.713 48.4766 332.131 49.8855C333.548 51.2945 334.535 53.3583 335.09 56.0771H315.834V56.0797Z" fill={logoTextColor}/>
            <path d="M149.85 40.6284L164.459 60.038L149.329 79.9224H161.64L170.612 67.8524L179.24 79.9224H192.114L162.69 40.644L149.85 40.6284Z" fill={logoTextColor}/>
            <path d="M191.366 40.6284H179.326L173.001 49.2595L184.874 49.2569L191.366 40.6284Z" fill={logoTextColor}/>
            <path d="M89.3694 30.5491V79.9015H100.772V59.9648L122.101 59.8187V49.4186L100.772 49.5073V40.6205L124.404 40.6283V30.5647L89.3694 30.5491Z" fill={logoTextColor}/>
        </svg>
    )
}

export default FixrateLogo