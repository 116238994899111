export const CHART_COLOR_TOKENS = [
    {
        hex: "#C0E055",
        rgb: "rgb(192, 224, 85)",
        token: "data1",
    },
    {
        hex: "#90D99E",
        rgb: "rgb(144, 217, 158)",
        token: "data2",
    },
    {
        hex: "#57A8D1",
        rgb: "rgb(87, 168, 209)",
        token: "data3",
    },
    {
        hex: "#2263C4",
        rgb: "rgb(34, 99, 196)",
        token: "data4",
    },
    {
        hex: "#764BB2",
        rgb: "rgb(118, 75, 178)",
        token: "data5",
    },
    {
        hex: "#CC619E",
        rgb: "rgb(204, 97, 158)",
        token: "data6",
    },
    {
        hex: "#BA2237",
        rgb: "rgb(186, 34, 55)",
        token: "data7",
    },
    {
        hex: "#DC582A",
        rgb: "rgb(220, 88, 42)",
        token: "data8",
    },
    {
        hex: "#E5B640",
        rgb: "rgb(229, 182, 64)",
        token: "data9",
    },
    {
        hex: "#F2E577",
        rgb: "rgb(242, 229, 119)",
        token: "data10",
    },
]

export const TOKEN_PURPLE = [
    {
        hex: "#F9F7FF",
        rgb: "rgb(249, 247, 255)",
        token: "primary purple-25",
    },
    {
        hex: "#eee9f5",
        rgb: "rgb(238, 233, 245)",
        token: "primary purple-50",
    },
    {
        hex: "#c9bce1",
        rgb: "rgb(201, 188, 225)",
        token: "primary purple-100",
    },
    {
        hex: "#af9cd2",
        rgb: "rgb(175, 156, 210)",
        token: "primary purple-200",
    },
    {
        hex: "#8b6ebe",
        rgb: "rgb(139, 110, 190)",
        token: "primary purple-300",
    },
    {
        hex: "#7552b1",
        rgb: "rgb(117, 82, 177)",
        token: "primary purple-400",
    },
    {
        hex: "#52279e",
        rgb: "rgb(82, 39, 158)",
        token: "primary purple-500",
    },
    {
        hex: "#4b2390",
        rgb: "rgb(75, 35, 144)",
        token: "primary purple-600",
    },
    {
        hex: "#3a1c70",
        rgb: "rgb(58, 28, 112)",
        token: "primary purple-700",
    },
    {
        hex: "#2d1557",
        rgb: "rgb(45, 21, 87)",
        token: "primary purple-800",
    },
    {
        hex: "#221042",
        rgb: "rgb(34, 16, 66)",
        token: "primary purple-900",
    },
];

export const TOKEN_SPRING_GREEN = [
    {
        hex: "#FBFEFD",
        rgb: "rgb(251, 254, 253)",
        token: "spring green-25",
    },
    {
        hex: "#f4fcf9",
        rgb: "rgb(244, 252, 249)",
        token: "spring green-50",
    },
    {
        hex: "#def6eb",
        rgb: "rgb(222, 246, 235)",
        token: "spring green-100",
    },
    {
        hex: "#cef1e2",
        rgb: "rgb(206, 241, 226)",
        token: "spring green-200",
    },
    {
        hex: "#b8ebd4",
        rgb: "rgb(184, 235, 212)",
        token: "spring green-300",
    },
    {
        hex: "#aae7cc",
        rgb: "rgb(170, 231, 204)",
        token: "spring green-400",
    },
    {
        hex: "#95e1bf",
        rgb: "rgb(149, 225, 191)",
        token: "spring green-500",
    },
    {
        hex: "#88cdae",
        rgb: "rgb(136, 205, 174)",
        token: "spring green-600",
    },
    {
        hex: "#6aa088",
        rgb: "rgb(106, 160, 136)",
        token: "spring green-700",
    },
    {
        hex: "#527c69",
        rgb: "rgb(82, 124, 105)",
        token: "spring green-800",
    },
    {
        hex: "#3f5f50",
        rgb: "rgb(63, 95, 80)",
        token: "spring green-900",
    },
];

export const TOKEN_FOREST_GREEN = [
    {
        hex: "#F8FAF9",
        rgb: "rgb(248, 250, 249)",
        token: "forest green-25",
    },
    {
        hex: "#e9ebea",
        rgb: "rgb(233, 235, 234)",
        token: "forest green-50",
    },
    {
        hex: "#bac1be",
        rgb: "rgb(186, 193, 190)",
        token: "forest green-100",
    },
    {
        hex: "#99a39e",
        rgb: "rgb(153, 163, 158)",
        token: "forest green-200",
    },
    {
        hex: "#6a7872",
        rgb: "rgb(106, 120, 114)",
        token: "forest green-300",
    },
    {
        hex: "#4d5e56",
        rgb: "rgb(77, 94, 86)",
        token: "forest green-400",
    },
    {
        hex: "#21362c",
        rgb: "rgb(33, 54, 44)",
        token: "forest green-500",
    },
    {
        hex: "#1e3128",
        rgb: "rgb(30, 49, 40)",
        token: "forest green-600",
    },
    {
        hex: "#17261f",
        rgb: "rgb(23, 38, 31)",
        token: "forest green-700",
    },
    {
        hex: "#121e18",
        rgb: "rgb(18, 30, 24)",
        token: "forest green-800",
    },
    {
        hex: "#0e1712",
        rgb: "rgb(14, 23, 18)",
        token: "forest green-900",
    },
];

export const TOKEN_SUNSET_ORANGE = [
    {
        hex: "#FFFBF5",
        rgb: "rgb(255, 251, 245)",
        token: "sunset orange-25",
    },
    {
        hex: "#fff6e9",
        rgb: "rgb(255, 246, 233)",
        token: "sunset orange-50",
    },
    {
        hex: "#ffe3ba",
        rgb: "rgb(255, 227, 186)",
        token: "sunset orange-100",
    },
    {
        hex: "#ffd699",
        rgb: "rgb(255, 214, 153)",
        token: "sunset orange-200",
    },
    {
        hex: "#ffc36a",
        rgb: "rgb(255, 195, 106)",
        token: "sunset orange-300",
    },
    {
        hex: "#ffb84d",
        rgb: "rgb(255, 184, 77)",
        token: "sunset orange-400",
    },
    {
        hex: "#ffa621",
        rgb: "rgb(255, 166, 33)",
        token: "sunset orange-500",
    },
    {
        hex: "#e8971e",
        rgb: "rgb(232, 151, 30)",
        token: "sunset orange-600",
    },
    {
        hex: "#b57617",
        rgb: "rgb(181, 118, 23)",
        token: "sunset orange-700",
    },
    {
        hex: "#8c5b12",
        rgb: "rgb(140, 91, 18)",
        token: "sunset orange-800",
    },
    {
        hex: "#6b460e",
        rgb: "rgb(107, 70, 14)",
        token: "sunset orange-900",
    },
];

export const TOKEN_BURNT_ORANGE = [
    {
        hex: "#FCF8F5",
        rgb: "rgb(252, 248, 245)",
        token: "burnt orange-25",
    },
    {
        hex: "#f8ede6",
        rgb: "rgb(248, 237, 230)",
        token: "burnt orange-50",
    },
    {
        hex: "#e8c7b2",
        rgb: "rgb(232, 199, 178)",
        token: "burnt orange-100",
    },
    {
        hex: "#ddac8c",
        rgb: "rgb(221, 172, 140)",
        token: "burnt orange-200",
    },
    {
        hex: "#cd8658",
        rgb: "rgb(205, 134, 88)",
        token: "burnt orange-300",
    },
    {
        hex: "#c36e38",
        rgb: "rgb(195, 110, 56)",
        token: "burnt orange-400",
    },
    {
        hex: "#b44a06",
        rgb: "rgb(180, 74, 6)",
        token: "burnt orange-500",
    },
    {
        hex: "#a44305",
        rgb: "rgb(164, 67, 5)",
        token: "burnt orange-600",
    },
    {
        hex: "#803504",
        rgb: "rgb(128, 53, 4)",
        token: "burnt orange-700",
    },
    {
        hex: "#632903",
        rgb: "rgb(99, 41, 3)",
        token: "burnt orange-800",
    },
    {
        hex: "#4c1f03",
        rgb: "rgb(76, 31, 3)",
        token: "burnt orange-900",
    },
];

export const TOKEN_ERROR_RED = [
    {
        hex: "#FFF8F7",
        rgb: "rgb(255, 248, 2470)",
        token: "error red-25",
    },
    {
        hex: "#F8E8E6",
        rgb: "rgb(248, 232, 230)",
        token: "error red-50",
    },
    {
        hex: "#EBB8B0",
        rgb: "rgb(235, 184, 176)",
        token: "error red-100",
    },
    {
        hex: "#E1968A",
        rgb: "rgb(225, 150, 138)",
        token: "error red-200",
    },
    {
        hex: "#D36654",
        rgb: "rgb(211, 102, 84)",
        token: "error red-300",
    },
    {
        hex: "#CA4933",
        rgb: "rgb(202, 73, 51)",
        token: "error red-400",
    },
    {
        hex: "#BD1B00",
        rgb: "rgb(189, 27, 0)",
        token: "error red-500",
    },
    {
        hex: "#AC1900",
        rgb: "rgb(172, 25, 0)",
        token: "error red-600",
    },
    {
        hex: "#861300",
        rgb: "rgb(134, 19, 0)",
        token: "error red-700",
    },
    {
        hex: "#680F00",
        rgb: "rgb(104, 15, 0)",
        token: "error red-800",
    },
    {
        hex: "#4F0B00",
        rgb: "rgb(79, 11, 0)",
        token: "error red-900",
    },
];

export const TOKEN_SILVER_GRAY = [
    {
        hex: "#F6F6F6",
        rgb: "rgb(246, 246, 246)",
        token: "silver grey-25",
    },
    {
        hex: "#f1f1f1",
        rgb: "rgb(241, 241, 241)",
        token: "silver grey-50",
    },
    {
        hex: "#d2d3d3",
        rgb: "rgb(210, 211, 211)",
        token: "silver grey-100",
    },
    {
        hex: "#bcbebe",
        rgb: "rgb(188, 190, 190)",
        token: "silver grey-200",
    },
    {
        hex: "#9ea1a0",
        rgb: "rgb(158, 161, 160)",
        token: "silver grey-300",
    },
    {
        hex: "#8b8e8d",
        rgb: "rgb(139, 142, 141)",
        token: "silver grey-400",
    },
    {
        hex: "#6e7271",
        rgb: "rgb(110, 114, 113)",
        token: "silver grey-500",
    },
    {
        hex: "#646867",
        rgb: "rgb(100, 104, 103)",
        token: "silver grey-600",
    },
    {
        hex: "#4e5150",
        rgb: "rgb(78, 81, 80)",
        token: "silver grey-700",
    },
    {
        hex: "#3d3f3e",
        rgb: "rgb(61, 63, 62)",
        token: "silver grey-800",
    },
    {
        hex: "#2e302f",
        rgb: "rgb(46, 48, 47)",
        token: "silver grey-900",
    },
];

export const TOKEN_UI_BLUE = [
    {
        hex: "#E9F6FC",
        rgb: "rgb(233, 246, 252)",
        token: "ui blue-25",
    },
    {
        hex: "#E6F2F9",
        rgb: "rgb(230, 242, 249)",
        token: "ui blue-50",
    },
    {
        hex: "#B0D6EC",
        rgb: "rgb(176, 214, 236)",
        token: "ui blue-100",
    },
    {
        hex: "#8AC2E3",
        rgb: "rgb(138, 194, 227)",
        token: "ui blue-200",
    },
    {
        hex: "#54A7D7",
        rgb: "rgb(84, 167, 215)",
        token: "ui blue-300",
    },
    {
        hex: "#3395CF",
        rgb: "rgb(51, 149, 207)",
        token: "ui blue-400",
    },
    {
        hex: "#007BC3",
        rgb: "rgb(0, 123, 195)",
        token: "ui blue-500",
    },
    {
        hex: "#0070B1",
        rgb: "rgb(0, 112, 177)",
        token: "ui blue-600",
    },
    {
        hex: "#00578A",
        rgb: "rgb(0, 87, 138)",
        token: "ui blue-700",
    },
    {
        hex: "#00446B",
        rgb: "rgb(0, 68, 107)",
        token: "ui blue-800",
    },
    {
        hex: "#003452",
        rgb: "rgb(0, 52, 82)",
        token: "ui blue-900",
    },
];

export const TOKEN_SUCCES_GREEN = [
    {
        hex: "#F6FFF6",
        rgb: "rgb(246, 255, 246)",
        token: "success green-25",
    },
    {
        hex: "#EDF6EE",
        rgb: "rgb(237, 246, 238)",
        token: "sucess green-50",
    },
    {
        hex: "#C7E4CC",
        rgb: "rgb(199, 228, 204)",
        token: "sucess green-100",
    },
    {
        hex: "#ABD7B3",
        rgb: "rgb(171, 215, 179)",
        token: "sucess green-200",
    },
    {
        hex: "#85C490",
        rgb: "rgb(133, 196, 144)",
        token: "sucess green-300",
    },
    {
        hex: "#6DB97A",
        rgb: "rgb(109, 185, 122)",
        token: "sucess green-400",
    },
    {
        hex: "#49A759",
        rgb: "rgb(73, 167, 89)",
        token: "sucess green-500",
    },
    {
        hex: "#429851",
        rgb: "rgb(66, 152, 81)",
        token: "sucess green-600",
    },
    {
        hex: "#34773F",
        rgb: "rgb(52, 119, 63)",
        token: "sucess green-700",
    },
    {
        hex: "#285C31",
        rgb: "rgb(40, 92, 49)",
        token: "sucess green-800",
    },
    {
        hex: "#1F4625",
        rgb: "rgb(31, 70, 37)",
        token: "sucess green-900",
    },
];

export const TOKEN_SIGNAL_YELLOW = [
    {
        hex: "#FFFFFA",
        rgb: "rgb(255, 255, 250)",
        token: "signal yellow-25",
    },
    {
        hex: "#FFFEF4",
        rgb: "rgb(255, 254, 244)",
        token: "signal yellow-50",
    },
    {
        hex: "#FFFBDE",
        rgb: "rgb(255, 251, 222)",
        token: "signal yellow-100",
    },
    {
        hex: "#FFF9CE",
        rgb: "rgb(255, 249, 206)",
        token: "signal yellow-200",
    },
    {
        hex: "#FEF7B8",
        rgb: "rgb(254, 247, 184)",
        token: "signal yellow-300",
    },
    {
        hex: "#FEF5AA",
        rgb: "rgb(254, 245, 170)",
        token: "signal yellow-400",
    },
    {
        hex: "#FEF395",
        rgb: "rgb(254, 243, 149)",
        token: "signal yellow-500",
    },
    {
        hex: "#E7DD88",
        rgb: "rgb(231, 221, 136)",
        token: "signal yellow-600",
    },
    {
        hex: "#B4AD6A",
        rgb: "rgb(180, 173, 106)",
        token: "signal yellow-700",
    },
    {
        hex: "#8C8652",
        rgb: "rgb(140, 134, 82)",
        token: "signal yellow-800",
    },
    {
        hex: "#6B663F",
        rgb: "rgb(107, 102, 63)",
        token: "signal yellow-900",
    },
];
