import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import { ActionBlock } from '#components'
import { useSelector } from '#state/useSelector'
import { Box } from '@mui/material'
import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ChooseOrganization } from './ChooseOrganization'

type Props = {
    visible: boolean
    setShowNewOrganization: (val: boolean) => void
}

export default function AddNewOrganization({visible, setShowNewOrganization}: Props) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [orgnum, setOrgnum] = useState('')
    const depositors = useSelector(({depositors}) => depositors)
    const depositor = depositors?.find?.(depositor => depositor.nationalIdentity === orgnum)

    useLayoutEffect(() => {
        if (depositor) {
            navigate(`/organizations/${depositor.id}`)
        }
    }, [depositor, navigate])

    return (
        <FxDialog onClose={() => setShowNewOrganization(false)} open={visible}>
            <FxDialogTitle onClose={() => setShowNewOrganization(false)}>{t('pages-organizations.newOrganizationHeading')}</FxDialogTitle>
            <FxDialogContent>
                <Box mt={3}>
                    <ChooseOrganization cancel={() => setShowNewOrganization(false)} orgnum={orgnum} setOrgnum={setOrgnum}/>
                </Box>
            </FxDialogContent>
        </FxDialog>
    )

}
