import { COLORS, SILVER_GRAY } from "#app/colors/colors"
import { DateOutput, LoadingSpinner, PageHeader, PageLayout } from '#app/components'
import { useCurrencyOutputWithCurrency } from "#components/CurrencyOutput/useCurrencyOutput"
import FixrateLogo from "#pages/menu/FixrateLogo"
import styles from "#pages/portfolio-depositor/Reports/FundReports/AccountingReport/AccountingReport.module.scss"
import { formatLongReportPeriod, getReportPeriodDates } from '#pages/portfolio-depositor/Reports/ReportPeriods'
import usePortfolio from "#services/usePortfolio"
import { FundAccountingReport, FundAccountingReportPdfProps, ShareClassInfo } from "@fixrate/fixrate-report"
import { fundPlacementsShareClassInfoSelector } from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { Currency } from "@fixrate/fixrate-query"
import {
    Alert, AlertTitle, Box, Button, FormControlLabel,
    Stack, Switch, Tooltip, Typography, useMediaQuery
} from '@mui/material'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from "react-query"
import { useParams } from 'react-router-dom'
import BalanceDevelopmentChart from './BalanceDevelopmentChart'

type Params = {
    period?: string
}

export default function AccountingReportPage() {
    const {t} = useTranslation()
    const { period } = useParams<Params>()
    const depositorId = useSelector(state => state.depositors.filter(d => d.fundData?.enabled).at(0)?.id)
    const placementsShareClassInfo: {[index: string]: ShareClassInfo} = useSelector(fundPlacementsShareClassInfoSelector)
    const [startDate, endDate] = getReportPeriodDates(period)
    const portfolio = usePortfolio()
    const currency: Currency = portfolio?.currency ?? 'NOK'
    const {data: report, isLoading, isError} = useQuery({
        queryFn: async () => {
            const data = await fetch(`/api/report/fund-accounting?depositorId=${depositorId}&startDate=${startDate}&endDate=${endDate}`)
            return await data.json() as FundAccountingReport
        },
        queryKey: ['AccountingReport', depositorId, startDate, endDate]
    })
    if (isLoading || Object.keys(placementsShareClassInfo).length === 0) {
        return <LoadingSpinner />
    }
    if (isError) {
        return <Alert severity="error">{t('pages-fund-reports.errorLoadingReport')}</Alert>
    }
    const downloadPdfLink = `/api/report/fund-accounting/pdf?depositorId=${depositorId}&startDate=${startDate}&endDate=${endDate}&period=${period}&download=attachment`
    const downloadExcelLink = `/api/report/fund-accounting/excel?depositorId=${depositorId}&startDate=${startDate}&endDate=${endDate}&period=${period}&download=attachment`
    return (
        <Fragment>
            <PageHeader backToLink={'/reports/funds'} icon="ri-line-chart-line" title={t('pages-fund-reports.fundReports')}></PageHeader>
            {report && (
                <AccountingReport report={report}
                                  placementsShareClassInfo={placementsShareClassInfo}
                                  period={period}
                                  currency={currency}
                                  showBalanceFundPlacementRowsDefault={false}
                                  showResultFundPlacementRowsDefault={false}
                                  downloadPdfLink={downloadPdfLink}
                                  downloadExcelLink={downloadExcelLink}
                />
            )}
        </Fragment>
    )
}

type AccountingReportPageProps = FundAccountingReportPdfProps & {
    pdfMode?: boolean
    downloadPdfLink?: string
    downloadExcelLink?: string
}

/**
 * NB: Do not add any session hooks to this component!
 * The component is used for PDF generation and cannot rely on dynamic session state.
 * Any session state should be passed as props.
 */
export function AccountingReport(
    {report, period, currency, showBalanceFundPlacementRowsDefault, showResultFundPlacementRowsDefault, pdfMode=false, downloadPdfLink, downloadExcelLink}
        : AccountingReportPageProps) {
    const [showBalanceFundPlacementRows, setShowBalanceFundPlacementRows] = useState(showBalanceFundPlacementRowsDefault)
    const [showResultFundPlacementRows, setShowResultFundPlacementRows] = useState(showResultFundPlacementRowsDefault)
    const {t} = useTranslation()
    const isPrint = useMediaQuery('print') || pdfMode
    return (
        <PageLayout style={{backgroundColor: 'white'}}>
            {isPrint && (
                <Box display={'flex'} justifyContent={'right'}>
                    <FixrateLogo className={styles.fixratePdfHeaderLogo}/>
                </Box>
            )}
            <Stack spacing={4} sx={{px: {xs: 2, md: 0}, maxWidth: '130rem'}}>
                <Stack>
                    {report?.toDate && <Box sx={{color: COLORS.PURPLE, fontWeight: 600, fontVariant: 'small-caps', textTransform: 'uppercase', mb: 1}}>{formatLongReportPeriod(period, t)}</Box>}
                    <Typography variant={'h1'}>{t('pages-fund-reports.reconciliationReport')}</Typography>
                </Stack>
                <BalanceDevelopmentSection report={report}
                                           currency={currency}
                                           pdfMode={isPrint}
                />
                <BalanceTableSection report={report}
                                     startDate={report.fromDate}
                                     endDate={report.toDate}
                                     showFundPlacementRows={showBalanceFundPlacementRows}
                                     setShowFundPlacementRows={setShowBalanceFundPlacementRows}
                                     currency={currency}
                />
                <ResultTableSection report={report}
                                    startDate={report.fromDate}
                                    endDate={report.toDate}
                                    showFundPlacementRows={showResultFundPlacementRows}
                                    setShowFundPlacementRows={setShowResultFundPlacementRows}
                                    currency={currency}
                />
                {!isPrint && downloadPdfLink && (
                    <Stack spacing={1} direction={'row'}>
                        <Box>
                            <Button href={downloadPdfLink + `&showBalanceFundPlacementRows=${showBalanceFundPlacementRows}&showResultFundPlacementRows=${showResultFundPlacementRows}`}
                                    startIcon={<i className="ri-file-pdf-2-line"></i>}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    variant={'contained'}
                            >
                                {t('pages-fund-reconciliation-report.downloadAsPdf')}
                            </Button>
                        </Box>
                        {downloadExcelLink && (
                            <Box>
                                <Button href={downloadExcelLink}
                                        startIcon={<i className="ri-file-excel-2-line"></i>}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        variant={'contained'}
                                        >
                                    {t('pages-fund-reconciliation-report.downloadAsSpreadsheet')}
                                </Button>
                            </Box>
                        )}
                    </Stack>
                )}
                <Alert severity="info" sx={{pageBreakInside:'avoid'}}>
                    <AlertTitle>{t('pages-fund-reconciliation-report.alertTitle')}</AlertTitle>
                    {t('pages-fund-reconciliation-report.alertMessage')}
                </Alert>
            </Stack>
        </PageLayout>
        )
}

type BalanceDevelopmentSectionProps = {
    report: FundAccountingReport
    currency: Currency
    pdfMode: boolean
}

/*
 For some reason using useMediaQuery('print') in this component or in BalanceDevelopmentChart makes the chart not render in PDF.
 Hence, we pass pdfMode as a prop.
 */
function BalanceDevelopmentSection({report, currency, pdfMode}: BalanceDevelopmentSectionProps) {
    const { t } = useTranslation()
    return (
        <Stack spacing={4}>
            <Typography variant={'h2'}>{t('pages-fund-reconciliation-report.balanceDevelopment')}</Typography>
            <Typography variant={'subtitle2'}>{t('pages-fund-reconciliation-report.closingBalanceVisualization')}</Typography>
            <Box width={'100%'} maxWidth={'100rem'}>
                <BalanceDevelopmentChart report={report} currency={currency} pdfMode={pdfMode}/>
            </Box>
        </Stack>
    )
}

type BalanceTableSectionProps = {
    report: FundAccountingReport
    startDate: string
    endDate: string
    showFundPlacementRows: boolean
    setShowFundPlacementRows: (value: boolean) => void
    currency: Currency
}

function BalanceTableSection({
        report,
        startDate, endDate,
        showFundPlacementRows, setShowFundPlacementRows, currency
} : BalanceTableSectionProps) {
    const { t } = useTranslation()
    const formatNumber = useNumberFormatter(currency)
    const isPrint = useMediaQuery('print')

    return (
        <Stack spacing={isPrint ? 1 : 4} sx={{pageBreakInside: 'avoid'}}>
            <Stack direction={'row'} justifyContent={'space-between'} pr={1}>
                <Typography variant={'h2'}>{t('pages-fund-reconciliation-report.balance')}</Typography>
                <FormControlLabel
                    sx={{ displayPrint: 'none' }}
                    label={t('pages-fund-reconciliation-report.showDetails')}
                    labelPlacement={'start'}
                    control={(
                        <Switch
                            size={'small'}
                            checked={showFundPlacementRows}
                            onChange={(e) => setShowFundPlacementRows(e.target.checked)}
                        />
                    )}
                />
            </Stack>
            <table className={styles.fundAccountingTable}>
                <BalanceHeader startDate={startDate} endDate={endDate}/>
                <tbody>
                {Object.entries(report.balanceReport.entries).map(([category, subReport]) => (
                    <Fragment key={category}>
                        <tr className={styles.subHeader}>
                            <td colSpan={5}>{t(`enum-SubReportCategory.${category}`)}</td>
                        </tr>
                        {Object.entries(subReport).map(([entryType, entry]) => (
                            <tr key={entryType}>
                                <td>
                                    <Stack spacing={0.5}>
                                        <span>{t(`enum-ReportEntryType.${entryType}`)}</span>
                                        {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                            <FundNameStackItem key={id} fundName={report.balanceReport.outgoingShareClassInfo[id]?.fullName}/>
                                        ))}
                                    </Stack>
                                </td>
                                <td className={styles.noPrint}>
                                    <Stack spacing={0.5}>
                                        <span>&nbsp;</span>
                                        {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                            <FundNameStackItem key={id} fundName={report.balanceReport.outgoingShareClassInfo[id]?.isin}/>
                                        ))}
                                    </Stack>
                                </td>
                                <td>
                                    <Stack spacing={0.5}>
                                        <span>{formatNumber(entry.incoming)}</span>
                                        {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                            <ValueStackItem key={id}>
                                                {formatNumber(entry.entries[id].incoming)}
                                            </ValueStackItem>
                                        ))}
                                    </Stack>
                                </td>
                                <td>
                                    <Stack spacing={0.5}>
                                        <span>{formatNumber(entry.difference)}</span>
                                        {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                            <ValueStackItem key={id}>
                                                {formatNumber(entry.entries[id].difference)}
                                            </ValueStackItem>
                                        ))}
                                    </Stack>
                                </td>
                                <td>
                                    <Stack spacing={0.5}>
                                        <span>{formatNumber(entry.outgoing)}</span>
                                        {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                            <ValueStackItem key={id}>
                                                {formatNumber(entry.entries[id].outgoing)}
                                            </ValueStackItem>
                                        ))}
                                    </Stack>
                                </td>
                            </tr>
                        ))}
                    </Fragment>
                ))}
                <tr className={styles.sumLine}>
                    <td>
                        <Stack spacing={0.5}>
                            <span>{t('pages-fund-reconciliation-report.sumBalanceMarketValue')}</span>
                            {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                <FundNameStackItem key={id} fundName={report.balanceReport.outgoingShareClassInfo[id]?.fullName}/>
                            ))}
                        </Stack>
                    </td>
                    <td className={styles.noPrint}>
                        <Stack spacing={0.5}>
                            <span>&nbsp;</span>
                            {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                <FundNameStackItem key={id} fundName={report.balanceReport.outgoingShareClassInfo[id].isin}/>
                            ))}
                        </Stack>
                    </td>
                    <td>
                        <Stack spacing={0.5}>
                            <span>{formatNumber(report.balanceReport.sumIncoming)}</span>
                            {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                <ValueStackItem key={id}>
                                    {formatNumber(report.balanceReport.sumEntries[id].incoming)}
                                </ValueStackItem>
                            ))}
                        </Stack>
                    </td>
                    <td>
                        <Stack spacing={0.5}>
                            <span>{formatNumber(report.balanceReport.sumDifference)}</span>
                            {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                <ValueStackItem key={id}>
                                    {formatNumber(report.balanceReport.sumEntries[id].difference)}
                                </ValueStackItem>
                            ))}
                        </Stack>
                    </td>
                    <td>
                        <Stack spacing={0.5}>
                            <span>{formatNumber(report.balanceReport.sumOutgoing)}</span>
                            {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                <ValueStackItem key={id}>
                                    {formatNumber(report.balanceReport.sumEntries[id].outgoing)}
                                </ValueStackItem>
                            ))}
                        </Stack>
                    </td>
                </tr>
                </tbody>
            </table>
        </Stack>
    )
}

type BalanceHeaderProps = {
    startDate: string
    endDate: string
}
function BalanceHeader({startDate, endDate}: BalanceHeaderProps) {
    const {t} = useTranslation()
    const isPrint = useMediaQuery('print')
    return (
        <Fragment>
            <colgroup>
                <col style={{width: isPrint ? '37%': '23%'}}/>
                {!isPrint && (<col style={{width: '14%'}}/>)}
                <col style={{width: '21%'}}/>
                <col style={{width: '21%'}}/>
                <col style={{width: '21%'}}/>
            </colgroup>
            <thead>
            <tr>
                <th>
                    <Stack>
                        <span>&nbsp;</span>
                        <span>{t('pages-fund-reconciliation-report.colName')}</span>
                    </Stack>
                </th>
                {!isPrint && (
                    <th>
                        <Stack>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                        </Stack>
                    </th>
                )}
                <th>
                    <Stack>
                        <span className={styles.date}>{DateOutput.formatDate(startDate)}</span>
                        <span style={{whiteSpace: 'nowrap'}}>{t('pages-fund-reconciliation-report.colIncomingBalance')}</span>
                    </Stack>
                </th>
                <th>
                    <Stack>
                        <span>&nbsp;</span>
                        <span>{t('pages-fund-reconciliation-report.colChanges')}</span>
                    </Stack>
                </th>
                <th>
                    <Stack>
                        <span className={styles.date}>{DateOutput.formatDate(endDate)}</span>
                        <span style={{whiteSpace: 'nowrap'}}>{t('pages-fund-reconciliation-report.colClosingBalance')}</span>
                    </Stack>
                </th>
            </tr>
            </thead>
        </Fragment>
    )
}

type ResultTableSectionProps = {
    report: FundAccountingReport
    startDate: string
    endDate: string
    showFundPlacementRows: boolean
    setShowFundPlacementRows: (value: boolean) => void
    currency: Currency
}

function ResultTableSection({ report, startDate, endDate, showFundPlacementRows, setShowFundPlacementRows, currency }: ResultTableSectionProps) {
    const { t } = useTranslation()
    const formatNumber = useNumberFormatter(currency)
    const startOfYear = startDate.substring(0, 4) + '-01-01'
    const isPrint = useMediaQuery('print')

    return (
        <Stack spacing={isPrint ? 1 : 4} sx={{pageBreakInside: 'avoid'}}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant={'h2'}>{t('pages-fund-reconciliation-report.result')}</Typography>
                <FormControlLabel
                    sx={{ displayPrint: 'none' }}
                    control={
                        <Switch
                            checked={showFundPlacementRows}
                            onChange={(e) => setShowFundPlacementRows(e.target.checked)}
                        />
                    }
                    label={t('pages-fund-reconciliation-report.showDetails')}
                    labelPlacement="start"
                />
            </Stack>
            <table className={styles.fundAccountingTable}>
                <ResultTableHeader startDate={startDate} endDate={endDate} startOfYear={startOfYear}/>
                <tbody>
                {Object.entries(report.resultReport.entries).map(([category, subReport]) => (
                    <Fragment key={category}>
                        <tr className={styles.subHeader}>
                            <td colSpan={5}>{t(`enum-SubReportCategory.${category}`)}</td>
                        </tr>
                        {Object.entries(subReport).map(([entryType, entry]) => (
                            <tr key={entryType}>
                                <td>
                                    <Stack spacing={0.5}>
                                        <Typography>
                                            <span>{t(`enum-ReportEntryType.${entryType}`)} </span>
                                            {entryType === 'RETROCESSION_CASH_VALUE' && (
                                                <Tooltip title={t('pages-fund-reconciliation-report.noCorrespondingBalanceEntry')} className={styles.noPrint}>
                                                    <i className="ri-information-line purple"/>
                                                </Tooltip>
                                            )}
                                        </Typography>
                                        {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                            <FundNameStackItem key={id} fundName={report.resultReport.outgoingShareClassInfo[id]?.fullName}/>
                                        ))}
                                    </Stack>
                                </td>
                                <td className={styles.noPrint}>
                                    <Stack spacing={0.5}>
                                        <span>&nbsp;</span>
                                        {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                            <FundNameStackItem key={id} fundName={report.resultReport.outgoingShareClassInfo[id]?.isin}/>
                                        ))}
                                    </Stack>
                                </td>
                                <td>
                                    <Stack spacing={0.5}>
                                        <span>{formatNumber(entry.difference)}</span>
                                        {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                            <ValueStackItem key={id}>
                                                {formatNumber(entry.entries[id].difference)}
                                            </ValueStackItem>
                                        ))}
                                    </Stack>
                                </td>
                                <td>
                                    {!startDate.endsWith('01-01') && (
                                        <Stack spacing={0.5}>
                                            <span>{formatNumber(entry.differenceThisYear)}</span>
                                            {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                                <ValueStackItem key={id}>
                                                    {formatNumber(entry.entries[id].differenceThisYear)}
                                                </ValueStackItem>
                                            ))}
                                        </Stack>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </Fragment>
                ))}
                <tr className={styles.sumLine}>
                    <td>
                        <Stack spacing={0.5}>
                            <span>{t('pages-fund-reconciliation-report.sumResult')}</span>
                            {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                <FundNameStackItem key={id} fundName={report.resultReport.outgoingShareClassInfo[id]?.fullName}/>
                            ))}
                        </Stack>
                    </td>
                    <td className={styles.noPrint}>
                        <Stack spacing={0.5}>
                            <span>&nbsp;</span>
                            {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                <FundNameStackItem key={id} fundName={report.resultReport.outgoingShareClassInfo[id]?.isin}/>
                            ))}
                        </Stack>
                    </td>
                    <td>
                        <Stack spacing={0.5}>
                            <span>{formatNumber(report.resultReport.sumDifference)}</span>
                            {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                <ValueStackItem key={id}>
                                    {formatNumber(report.resultReport.sumEntries[id].difference)}
                                </ValueStackItem>
                            ))}
                        </Stack>
                    </td>
                    <td>
                        {!startDate.endsWith('01-01') && (
                            <Stack spacing={0.5}>
                                <span>{formatNumber(report.resultReport.sumDifferenceThisYear)}</span>
                                {showFundPlacementRows && report.fundPlacementIds.map(id => (
                                    <ValueStackItem key={id}>
                                        {formatNumber(report.resultReport.sumEntries[id].differenceThisYear)}
                                    </ValueStackItem>
                                ))}
                            </Stack>
                        )}
                    </td>
                </tr>
                </tbody>
            </table>
        </Stack>
    )
}

type ResultTableHeaderProps = {
    startDate: string
    endDate: string
    startOfYear: string
}

function ResultTableHeader({startDate, endDate, startOfYear}: ResultTableHeaderProps) {
    const {t} = useTranslation()
    const isPrint = useMediaQuery('print')
    return (
        <Fragment>
            <colgroup>
                <col style={{width: isPrint ? '37%' : '23%'}}/>
                {!isPrint && (<col style={{width: '14%'}}/>)}
                <col style={{width: '42%'}}/>
                <col style={{width: '21%'}}/>
            </colgroup>
            <thead>
            <tr>
                <th>
                    <Stack>
                        <span>&nbsp;</span>
                        <span>{t('pages-fund-reconciliation-report.colName')}</span>
                    </Stack>
                </th>
                {!isPrint && (
                    <th>
                        <Stack>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                        </Stack>
                    </th>
                )}
                <th>
                    <Stack>
                        <span className={styles.date}>{DateOutput.formatDate(startDate)} - {DateOutput.formatDate(endDate)}</span>
                        <span style={{whiteSpace: 'nowrap'}}>{t('pages-fund-reconciliation-report.colChangesThisPeriod')}</span>
                    </Stack>
                </th>
                <th>
                    {!startDate.endsWith('01-01') && (
                        <Stack>
                            <span className={styles.date}>{DateOutput.formatDate(startOfYear)} - {DateOutput.formatDate(endDate)}</span>
                            <span style={{whiteSpace: 'nowrap'}}>{t('pages-fund-reconciliation-report.colChangesThisYear')}</span>
                        </Stack>
                    )}
                </th>
            </tr>
            </thead>
        </Fragment>
    )
}

function FundNameStackItem({fundName}: {fundName: string | undefined}) {
    return (
        <Tooltip title={fundName ?? ''} placement={'top'} arrow>
            <Box component={'span'} sx={{
                pl: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: SILVER_GRAY[400],
            }}>
                {fundName}
            </Box>
        </Tooltip>
    )
}

function ValueStackItem({children}: {children?: React.ReactNode}) {
    return (
        <Box component={'span'} sx={{color: SILVER_GRAY[400]}}>
            {children}
        </Box>
    )
}

const useNumberFormatter = (currency: Currency) => {
    const { t } = useTranslation()
    const Currency = useCurrencyOutputWithCurrency(currency)
    // eslint-disable-next-line react/display-name
    return (value: number) => {
        if (value === 0) {
            return <Tooltip title={t('pages-fund-reports.valueIsZero')}><span>-</span></Tooltip>
        } else if (!value) {
            return <Tooltip title={t('pages-fund-reports.valueNotAvailable')}><span>{'\u2026'}</span></Tooltip>
        } else {
            return Currency(value)
        }
    }
}


