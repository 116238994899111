import { useRef } from 'react';
import {Chart} from 'chart.js'
import 'chartjs-adapter-date-fns'
import {InterestOutput} from '#components'
import {FundInventoryReport} from '@fixrate/fixrate-report'
import {CHART_COLORS} from '#app/colors/colors'
import {Box, Stack} from '@mui/material'
import LegendSquare from "#pages/portfolio-depositor/Reports/FundReports/InventoryReport/LegendSquare";

type Props = {
    report: FundInventoryReport
    pdfMode?: boolean
}

type DoughnutChart = Chart<'doughnut', number[]>

export default function PortfolioSplitChart({report, pdfMode=false}: Props) {
    const chartRef = useRef<DoughnutChart | null>(null)

    const filteredEntries = report.fundPlacementInventoryEntries.filter(entry => {
        // Only include entries with a non-null and non-zero proportion.
        return entry.proportionOfPortfolio != null && entry.proportionOfPortfolio != 0
    })

    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        const ctx = canvas?.getContext('2d')
        if (ctx && !chartRef.current) {
            chartRef.current = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    labels: filteredEntries.map(e => e.fundName),
                    datasets: [{
                        backgroundColor: CHART_COLORS,
                        data: filteredEntries.map(e => e.proportionOfPortfolio),
                    }],
                },
                options: {
                    color: '#666',
                    maintainAspectRatio: !pdfMode,
                    animation: pdfMode ? false : {duration: 100},
                    hover: {
                        mode: null,
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                        },
                    },
                },
            })
        }
    }

    const webStyleCanvas = {
        maxWidth: '100%',
        aspectRatio: '1/1'
    }
    const pdfStyleCanvas = {
        height: '20rem',
        width: '20rem',
        aspectRatio: '1/1'
    }
    const canvasStyle = pdfMode ? pdfStyleCanvas : webStyleCanvas
    const webSxBox = {
        flexGrow: 1,
        maxWidth: '20rem'
    }
    const pdfSxBox = {
        width: '20rem'
    }
    const sxBox = pdfMode ? pdfSxBox : webSxBox

    return (
        <Stack direction={pdfMode ? 'row' : {xs: 'column', md: 'row'}}
               spacing={2}
        >
            <Box sx={sxBox}>
                <canvas ref={canvasCallback} style={canvasStyle} id={'portfolio-split-chart-canvas'}/>
            </Box>
            <Stack spacing={1} direction={'column'}>
                {filteredEntries.map((entry, i) => (
                    <Stack key={entry.fundId}
                           direction={'row'}
                           justifyContent={'space-between'}
                           spacing={4}
                    >
                        <Stack direction={'row'}
                               alignItems={'start'}
                               spacing={1}
                               key={entry.fundIsin}
                        >
                            <LegendSquare color={CHART_COLORS[i]}/>
                            <Stack>
                                <Box component="span" sx={{fontSize: 12, fontWeight: 600}}>{entry.fundName}</Box>
                                <Box component="span" sx={{fontSize: 12, color: '#6F7271'}}>{entry.fundIsin}</Box>
                            </Stack>
                        </Stack>
                        <Box component="span" sx={{fontSize: 14, fontWeight: 600}}>{InterestOutput.formatNoDecimal(entry.proportionOfPortfolio)}</Box>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    )
}
